import { PaletteOptions } from '@mui/material'

export const applogiePalette: PaletteOptions = {
  mode: 'light',
  primary: {
    main: '#30a289',
  },
  secondary: {
    main: '#757575',
  },
  background: {
    // default: '#d9d9d9',
    paper: '#ffffff',
  },
  success: {
    main: '#2e7d32',
  },
  error: {
    main: '#d32f2f',
  },
  warning: {
    main: '#ed6c02',
  },
  info: {
    main: '#0288d1',
  },
  text: {
    primary: '#000000',
  },
}
