import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { GridCellParams, GridColDef } from '@mui/x-data-grid-premium'
import React from 'react'
import { useAppDispatch } from 'reduxStore/hooks'
import { numberWithDecimalCommas } from 'utils/numberWithCommas'
import {
  selectedSubscriptionPaymentDetails,
  updateSubscriptionPaymentEditDialogState,
} from 'reduxStore/account/accountUpdatePayment/accountUpdatePaymentSlice'
import {
  enableDeleteSubscriptionPaymentDialog,
  selectItemForDeleteSubscriptionPayment,
} from 'reduxStore/account/accountPaymentDeleteByID/deletePaymentByIDSlice'
import EditNoteIcon from '@mui/icons-material/EditNote';
import { detectWrongPaymentDetailsID, detectWrongPaymentDialogState } from 'reduxStore/account/detectWrongPayment/detectWrongPaymentSlice'
import { renderApplogieDateUSFormat } from 'components/Utility'
export function SubscriptionPaymentListTableColumn() {
  let getIndividualUsageUpdate = localStorage.getItem('allowIndividualSpendUpdate')
  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Date',
      sortable: true,
      hideable: true,
      minWidth: 110,
      align: 'left',
      renderCell: (params: GridCellParams) => (
        <Box>{renderApplogieDateUSFormat(params.row.date) !== null && renderApplogieDateUSFormat(params.row.date)}</Box>
      ),
    },
    {
      field: 'amount',
      headerName: 'Payment',
      sortable: true,
      hideable: false,
      minWidth: 110,
      align: 'left',
      renderCell: (params: GridCellParams) => {
        return (
          <Typography sx={{ pt: 2, fontSize: '14px' }}>
            {params.row.amount !== null ? numberWithDecimalCommas(params.row.amount) : null}
          </Typography>
        )
      },
    },
    {
      field: 'memo',
      headerName: 'Memo',
      sortable: true,
      hideable: true,
      minWidth: 350,
      align: 'left',
      flex: 1
    },
    {
      field: 'id',
      headerName: 'Action',
      sortable: false,
      hideable: false,
      align: "center",
      minWidth: getIndividualUsageUpdate !== null ?
        getIndividualUsageUpdate === 'true' ? 150 : 80 : 80,
      renderCell: (params: GridCellParams) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const dispatch = useAppDispatch()
        return (
          <React.Fragment>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={0.5}
            >
              {getIndividualUsageUpdate !== null ? (
                getIndividualUsageUpdate === 'true' ? (
                  <Grid item>
                    <Tooltip title="Edit Payment">
                      <IconButton
                        aria-label="edit"
                        onClick={() => {
                          dispatch(updateSubscriptionPaymentEditDialogState(true))
                          dispatch(selectedSubscriptionPaymentDetails(params.row))
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                ) : null
              ) : null}
              {getIndividualUsageUpdate !== null ? (
                getIndividualUsageUpdate === 'true' ? (
                  <Grid item>
                  <Tooltip title="Delete Payment">
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        dispatch(enableDeleteSubscriptionPaymentDialog(true))
                        dispatch(selectItemForDeleteSubscriptionPayment(String(params.row.id)))
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                  </Grid>) : null
              ) : null}
              <Grid item>
              <Tooltip title="Transfer Payment">
                <IconButton
                  aria-label="transfer"
                  onClick={() => {
                    dispatch(detectWrongPaymentDialogState(true))
                    dispatch(detectWrongPaymentDetailsID(params.row))
                  }}
                >
                  <EditNoteIcon />
                </IconButton>
              </Tooltip>
              </Grid>
            </Grid>
          </React.Fragment>
        )
      },
    },

  ]
  return columns
}
