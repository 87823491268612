import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-premium';
import React from 'react';

export default function MUIDGCustomToolbar() {
  return (
    <GridToolbarContainer style={{ justifyContent: 'flex-start', gap: '16px' }}>
      <GridToolbarColumnsButton
        slotProps={{
          button: {
            variant: 'text',
            style: {
              color: '#1976d2',
              textTransform: 'uppercase',
              fontWeight: 600,
            },
          },
        }}
      />
      <GridToolbarFilterButton
        slotProps={{
          button: {
            variant: 'text',
            style: {
              color: '#1976d2',
              textTransform: 'uppercase',
              fontWeight: 600,
            },
          },
        }}
      />
      <GridToolbarDensitySelector
        slotProps={{
          button: {
            variant: 'text',
            style: {
              color: '#1976d2',
              textTransform: 'uppercase',
              fontWeight: 600,
            },
          },
        }}
      />
      <GridToolbarExport
        slotProps={{
          button: {
            variant: 'text',
            style: {
              color: '#1976d2',
              textTransform: 'uppercase',
              fontWeight: 600,
            },
          },
        }}
      />
    </GridToolbarContainer>

  );
}
